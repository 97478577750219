<template>
    <div class="friends">
        <div class="wrapper">
            <invite-widget />

            <sort-filters
                :sort-fields="sortFields"
                :sort-order="searchObject.sort_order"
                @actionUpdateFilter="onActionUpdateFilter"
            >
                <!-- <template #rightSide>
                    <friends-top-bar
                        :loading="totalsLoading"
                        :counters="counters"
                    />
                </template> -->
            </sort-filters>

            <radius-loader v-if="isLoading" />

            <no-result
                v-else-if="!isLoading && !friends.length"
                :type="'friends'"
            />
            <div
                v-if="!isLoading && friends.length"
                class="friends_list"
            >
                <router-link
                    v-for="(friend, index) in friends"
                    :key="index"
                    :to="{ name: 'friend_detail', params: { id: friend.id }}"
                    class="friends_list_card"
                >
                    <div class="friends_list_card-left">
                        <div class="friends_list_card-left__user">
                            <span
                                v-if="friend.country"
                                class="friends_list_card-left__user__flag"
                                :class="`review__flag flag-icon flag-icon-` + friend.country.toLowerCase()"
                            />
                            <div
                                v-else
                                class="friends_list_card__avatar"
                            >
                                <avatar
                                    :firstname="friend.firstname"
                                    :lastname="friend.lastname"
                                    :email="friend.email"
                                />
                            </div>
                            <div class="friends_list_card-left__user-info">
                                <span
                                    class="friends-card__name"
                                >
                                    {{ `${friend.lastname}, ${friend.firstname} ` }}
                                </span>

                                <span class="friends-card__email">{{ friend.email }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="friends_list_card-right friends_list_card-right-register">
                        <!-- total credits -->
                        <div class="friends-card__credits">
                            {{ friend.purchases | money }}
                            <br>
                            <span>purchases</span>
                        </div>
                        <!-- total credits -->

                        <!-- total orders -->
                        <div
                            class="friends-card__orders"
                        >
                            {{ friend.orders }}
                            <br><span>orders</span>
                        </div>
                        <!-- total orders -->
                        <p class="friends_list_card-right__date">
                            {{ friend.created_at | moment_bidding_text }}
                        </p>
                        <p
                            v-if="friend.status"
                            class="friends_list_card-right__status"
                        >
                            {{ friend.status.toLowerCase() }}
                        </p>
                    </div>
                </router-link>
                <custom-button
                    v-if="searchObject.page < lastPage"
                    default
                    :loading="isLoading"
                    class="btn-base_colored sort-button btn-page-location"
                    @on-btn-click="loadMore"
                >
                    Load more
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filtersMixin.js'
import { eventBus } from '@/helpers/event-bus/'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import RadiusLoader from '@/components/common/RadiusLoader'
import NoResult from '@/components/common/NoResult';
import Avatar from '@/components/common/Avatar';
// import FriendsTopBar from '@/components/friends/FriendsTopBar.vue'
import SortFilters from '@/components/SortFilters.vue'
import InviteWidget from '@/components/friends/InviteWidget.vue';

export default {
    components: {
        NoResult,
        Avatar,
        // FriendsTopBar,
        SortFilters,
        InviteWidget,
        RadiusLoader
    },
    metaInfo: {
        title: 'My Friends'
    },
    mixins: [filtersMixin],
    data() {
        return {
            searchObject: {
                sort_by: 'created_at',
                sort_order: 'DESC',
                per_page: 10,
                page: 1
            },
            friends: [],
            isLoading: false,
            counters: null,
            totalsLoading: false
        }
    },
    computed: {
        ...mapGetters('client', {
            sumEarninngs: 'getterStatisticsTotalEarningFriends'
        }),
        sortFields() {
            return [
                {
                    name: 'Date',
                    value: 'created_at'
                },
                {
                    name: 'Purchases',
                    value: 'purchases'
                }
            ]
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        lastPage() {
            return Math.ceil(this.counters?.friends / this.searchObject.per_page)
        }
    },
    async created() {
        this.searchObject = {
            ...this.searchObject,
            ...this.queryObject,
            page: 1
        }
        await this.getData();
    },
    methods: {
        async loadMore() {
            this.searchObject.page += 1
            await this.getData(false)
        },
        async onActionUpdateFilter(filter) {
            this.searchObject = { ...this.searchObject, ...filter, page: 1 }
            this.friends = []
            await this.getData(false)
        },
        async getData(withCout = true) {
            try {
                this.isLoading = true
                let requests = []
                requests = [...requests, Api.get('/api/client/friend/list-by-params', removeEmptyParamsFromRequest({ mode: 'list', ...this.searchObject }))]
                if (withCout) {
                    this.totalsLoading = true
                    requests = [...requests, Api.get('/api/client/friend/list-by-params', removeEmptyParamsFromRequest({ mode: 'count', ...this.searchObject }))]
                }
                const responses = await Promise.all(requests)
                const { data } = responses[0]
                if (responses[1]) {
                    const { data: totals } = responses[1]
                    this.counters = totals
                }
                this.friends = [...this.friends, ...data.data]
                if (JSON.stringify(this.searchObject) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: removeEmptyParamsFromRequest({ ...this.searchObject }) })
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
                this.totalsLoading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .friends {
        .sort-filter{
            top: 0;
            margin: 30px 0;
        }
        &_info {
            margin: 30px 0;
            font-size: 18px;
            line-height: 1.4;
            text-align: left;
            color: #000;
            font-weight: 300;
            word-break: normal;
            &-title {
                font-weight: 700;
                font-size: 18px;
                margin-top: 18px;
            }
        }
    }
    .friends_sort-menu-item {
        text-transform: capitalize;
        padding: 22px;
        &--active {
            .filter-bar__sort-order {
                display: block;
                opacity: 1;
            }
        }
    }
    .friends_sort-menu-arrow--top {
        transform: rotateX(-180deg);
    }
    .filter-bar__sort-order {
        opacity: 0;
        transition: 0.2s;
        margin-left: 4px;
    }
    .wrapper {
        // .no-result {
        //     padding: 0 15px;
        // }
        .bc-wrapper_row-btns {
            .btn-base {
                &:first-child {
                    margin-right: 20px;
                }
                margin-left: 0;
                margin-right: 0;
            }
        }
        .btn-base {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 480px){
        .wrapper {
            .bc-wrapper_row-btns {
                .btn-base {
                    &:first-child {
                        margin-right: 0px;
                    }
                }
            }
        }
        .friends {
            &-card__email {
                width: 330px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            &_sort {
                padding: 25px 15px;
                &-text {
                    font-size: 16px;
                }
                &-menu-item {
                    padding: 10px;
                }
            }
            .sort-filter {
                top: 0;
            }
        }
    }
</style>

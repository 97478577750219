<template>
    <div class="sort-filter">
        <div class="sort-filter_panel">
            <span class="sort-filter-title">Sort by:</span>
            <template v-for="(item, index) in sortFields">
                <span
                    :key="index"
                    class="sort-filter-link"
                    :class="{ 'router-link-active': sortObject.sort_by === item.value }"
                    @click="changeLink(item.value, 1)"
                >
                    {{ item.name }}
                    <span
                        class="sort-filter-order"
                        :class="{ 'sort-filter-order_desc': sortObject.sort_order === 'DESC' }"
                    ><ArrowDownBold /></span>
                </span>
            </template>
        </div>
        <div class="right-side">
            <slot name="rightSide" />
        </div>
    </div>
</template>

<script>

import ArrowDownBold from 'mdi-vue/ArrowDownBold.vue'

export default {
    name: 'BillingFilters',
    components: {
        ArrowDownBold
    },
    props: {
        sortFields: {
            type: Array,
            required: true
        },
        sortOrder: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            sortObject: {}
        }
    },
    created() {
        this.sortObject = {
            sort_by: this.sortFields[0].value,
            sort_order: this.sortOrder ?? 'DESC'
        }
    },
    methods: {
        changeLink(sort) {
            if (this.sortObject.sort_by === sort) {
                this.sortObject.sort_order = this.sortObject.sort_order === 'ASC' ? 'DESC' : 'ASC'
            } else {
                this.sortObject.sort_by = sort
                this.sortObject.sort_order = 'ASC'
            }
            this.$emit('actionUpdateFilter', this.sortObject)
        }
    }
}
</script>

<style lang="scss">

.sort-filter {
    @include media768max {
        flex-wrap: wrap;
        &-title {
            display: none;
        }
        &-link {
            padding: 10px;
            @media all and (max-width: 540px){
                width: 50%;
                text-align: center;
            }
        }
    }
    &_panel  {
        white-space: nowrap;
        @media all and (max-width: 540px){
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
    .right-side{
        margin-left: auto;
        @include media768max {
            .friend-bar {
                &_details {
                    @include media576max {
                        margin-top: 0;
                    }
                }
                &_details__item{
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                &_details__item-text{
                    .number {
                        font-size: 18px;
                    }
                    .text {
                        font-size: 18px;
                    }
                }
            }
        }
        @media all and (max-width: 540px){
            margin: 0 auto;
        }
    }
}
</style>
